port module Main exposing (main)

import Assets.NewSvg
import Assets.Svg
import Browser
import Browser.Navigation as Nav
import Html exposing (..)
import Html.Attributes as HtmlAttributes exposing (class, href, src, style, target)
import Html.Events exposing (onClick, onInput, onSubmit)
import Http
import Json.Decode
import Json.Decode.Pipeline as DecodePipeline
import Page.Account
import Page.Article
import Page.Articles
import Page.CEArticle
import Page.ClinicEducation
import Page.ClinicEducationArticle
import Page.ClinicResource
import Page.ClinicResourceArticle
import Page.Contact
import Page.Home
import Page.Login
import Page.LoginWithPassword
import Page.PrivacyPolicy
import Page.Questions
import Page.Quizzes
import Page.Register
import Page.Webinar
import Post exposing (PostScrenView)
import Route
import Svg as Svg exposing (path, svg)
import Svg.Attributes as SvgAttr
import Url
import Url.Builder
import User


port iframeContainerReady : String -> Cmd msg


port openWindow : String -> Cmd msg


port authChanged : (Maybe String -> msg) -> Sub msg


port sendScreenView : PostScrenView -> Cmd msg


port logout : () -> Cmd msg


type alias Flags =
    { contentUrl : String
    , contentApiKey : String
    , ceServiceUrl : String
    }


type Auth
    = LoggedIn String
    | NotLogin
    | Unknown
    | NoInformation String
    | CheckingVetInfo String


type State
    = InvalidFlags
    | CheckLoginStatus Flags (Maybe Route.Route)
    | Login Page.Login.State Flags
    | Home Page.Home.State Flags
    | Register Page.Register.State Flags
    | Account Page.Account.State Flags
    | Article Page.Article.State Flags
    | CEQuizzes Page.Quizzes.State Flags
    | CEArticle
        Page.CEArticle.State
        Flags
        { quizTitle : String
        , quizStartDate : String
        , quizEndDate : String
        , slug : String
        }
    | Questions
        Page.Questions.State
        Flags
        { quizTitle : String
        , quizStartDate : String
        , quizEndDate : String
        }
    | ClinicEducation Page.ClinicEducation.State Flags
    | ClinicEducationArticle Page.ClinicEducationArticle.State Flags
    | Articles Page.Articles.State Flags
    | ClinicResource Page.ClinicResource.State Flags
    | ClinicResourceArticle Page.ClinicResourceArticle.State Flags
    | Webinar Page.Webinar.State Flags
    | Contact
    | PrivacyPolicy
    | LoginWithPassword Page.LoginWithPassword.State Flags


type alias Model =
    { key : Nav.Key
    , url : Url.Url
    , flags : Maybe Flags
    , auth : Auth
    , openMenu : Bool
    , searchKeyword : String
    , state : State
    }


type Msg
    = HomeMsg Page.Home.Msg
    | LoginMsg Page.Login.Msg
    | ArticleMsg Page.Article.Msg
    | ArticlesMsg Page.Articles.Msg
    | CEQuizzesMsg Page.Quizzes.Msg
    | CEArticleMsg Page.CEArticle.Msg
    | QuestionsMsg Page.Questions.Msg
    | ClinicEducationMsg Page.ClinicEducation.Msg
    | ClinicEducationArticleMsg Page.ClinicEducationArticle.Msg
    | ClinicResourceMsg Page.ClinicResource.Msg
    | ClinicResourceArticleMsg Page.ClinicResourceArticle.Msg
    | WebinarMsg Page.Webinar.Msg
    | UrlRequested Browser.UrlRequest
    | UrlChanged Url.Url
    | RegisterMsg Page.Register.Msg
    | AccountMsg Page.Account.Msg
    | AuthChanged (Maybe String)
    | CheckUserExists (Result Http.Error (List User.UserEmail))
    | UserCreated (Result Http.Error ())
    | VetInfoFetched (Result Http.Error (List User.VetInfo))
    | Logout
    | MenuSelected
    | LoginWithPasswordMsg Page.LoginWithPassword.Msg
    | SearchTextChanged String
    | SearchSelected


flagsDecoder : Json.Decode.Decoder Flags
flagsDecoder =
    Json.Decode.succeed Flags
        |> DecodePipeline.required "contentUrl" Json.Decode.string
        |> DecodePipeline.required "contentApiKey" Json.Decode.string
        |> DecodePipeline.required "ceServiceUrl" Json.Decode.string


decodeUrlPath : String -> String
decodeUrlPath path =
    Maybe.withDefault "ไม่มีข้อมูล"
        (Url.percentDecode
            path
        )


toState : Flags -> (State -> Model) -> Maybe Route.Route -> ( Model, Cmd Msg )
toState flags model maybeRoute =
    let
        checkLoginStatusModel =
            model <| CheckLoginStatus flags maybeRoute
    in
    case checkLoginStatusModel.auth of
        LoggedIn email ->
            case maybeRoute of
                Just (Route.Article category slug) ->
                    let
                        ( articleState, articleCmd ) =
                            Page.Article.init flags.contentUrl flags.contentApiKey slug
                    in
                    ( model <| Article articleState flags, Cmd.map ArticleMsg articleCmd )

                Just Route.Quizzes ->
                    let
                        ( ceQuizzesState, ceQuizzessCmd ) =
                            Page.Quizzes.init flags.contentUrl flags.contentApiKey flags.ceServiceUrl
                    in
                    ( model <| CEQuizzes ceQuizzesState flags, Cmd.map CEQuizzesMsg ceQuizzessCmd )

                Just (Route.CEArticle slug quizTitle quizStartDate quizEndDate) ->
                    let
                        ( ceArticleState, ceArticleCmd ) =
                            Page.CEArticle.init flags.contentApiKey flags.contentUrl (decodeUrlPath quizTitle) quizStartDate quizEndDate (decodeUrlPath slug)
                    in
                    ( model <|
                        CEArticle ceArticleState
                            flags
                            { quizTitle = decodeUrlPath quizTitle
                            , quizStartDate = quizStartDate
                            , quizEndDate = quizEndDate
                            , slug = decodeUrlPath slug
                            }
                    , Cmd.map CEArticleMsg ceArticleCmd
                    )

                Just (Route.Questions quizTitle quizStartDate quizEndDate) ->
                    let
                        ( questionsState, questionsCmd ) =
                            Page.Questions.init flags.ceServiceUrl (decodeUrlPath quizTitle) quizStartDate quizEndDate email
                    in
                    ( model <|
                        Questions questionsState
                            flags
                            { quizTitle = decodeUrlPath quizTitle
                            , quizStartDate = quizStartDate
                            , quizEndDate = quizEndDate
                            }
                    , Cmd.map QuestionsMsg questionsCmd
                    )

                Just (Route.Articles slug) ->
                    let
                        ( articlesState, articlesCmd ) =
                            Page.Articles.init flags.contentApiKey flags.contentUrl (Maybe.withDefault "" (Url.percentDecode slug))
                    in
                    ( model <| Articles articlesState flags, Cmd.map ArticlesMsg articlesCmd )

                Just (Route.ClinicEducation slug) ->
                    let
                        ( clinicEducationState, clinicEducationCmd ) =
                            Page.ClinicEducation.init flags.contentApiKey flags.contentUrl slug
                    in
                    ( model <| ClinicEducation clinicEducationState flags, Cmd.map ClinicEducationMsg clinicEducationCmd )

                Just (Route.ClinicEducationArticle category slug) ->
                    let
                        ( articleState, articleCmd ) =
                            Page.ClinicEducationArticle.init flags.contentUrl flags.contentApiKey category slug email flags.ceServiceUrl
                    in
                    ( model <| ClinicEducationArticle articleState flags
                    , Cmd.map ClinicEducationArticleMsg articleCmd
                    )

                Just (Route.ClinicResources slug) ->
                    let
                        ( clinicResourceState, clinicResourceCmd ) =
                            Page.ClinicResource.init flags.contentApiKey flags.contentUrl slug
                    in
                    ( model <| ClinicResource clinicResourceState flags
                    , Cmd.map ClinicResourceMsg clinicResourceCmd
                    )

                Just (Route.ClinicResourceArticle category slug) ->
                    let
                        ( articleState, articleCmd ) =
                            Page.ClinicResourceArticle.init flags.contentUrl flags.contentApiKey category slug email flags.ceServiceUrl
                    in
                    ( model <| ClinicResourceArticle articleState flags
                    , Cmd.map ClinicResourceArticleMsg articleCmd
                    )

                Just Route.Webinar ->
                    let
                        ( webinarState, webinarCmd ) =
                            Page.Webinar.init email flags.contentApiKey flags.contentUrl
                    in
                    ( model <| Webinar webinarState flags
                    , Cmd.map WebinarMsg webinarCmd
                    )

                Just Route.Register ->
                    let
                        ( registerState, registerCmd ) =
                            Page.Register.init
                                flags.ceServiceUrl
                                email
                    in
                    ( model <| Register registerState flags
                    , Cmd.map RegisterMsg registerCmd
                    )

                Just Route.Account ->
                    let
                        ( accountState, accountCmd ) =
                            Page.Account.init
                                flags.ceServiceUrl
                                email
                    in
                    ( model <| Account accountState flags
                    , Cmd.map AccountMsg accountCmd
                    )

                Just Route.Contact ->
                    ( model <| Contact
                    , Cmd.none
                    )

                Just Route.PrivacyPolicy ->
                    ( model <| PrivacyPolicy
                    , Cmd.none
                    )

                _ ->
                    let
                        ( homeState, homeCmd ) =
                            Page.Home.init
                                flags.contentUrl
                                flags.contentApiKey
                                flags.ceServiceUrl
                                email
                    in
                    ( model <| Home homeState flags
                    , Cmd.map HomeMsg homeCmd
                    )

        NotLogin ->
            case maybeRoute of
                Just Route.Home ->
                    let
                        ( homeState, homeCmd ) =
                            Page.Home.init
                                flags.contentUrl
                                flags.contentApiKey
                                flags.ceServiceUrl
                                ""
                    in
                    ( model <| Home homeState flags
                    , Cmd.map HomeMsg homeCmd
                    )

                Just Route.Login ->
                    ( model <| Login Page.Login.init flags, Cmd.none )

                Just Route.LoginWithPassword ->
                    ( model <| LoginWithPassword Page.LoginWithPassword.init flags, Cmd.none )

                _ ->
                    ( model <| LoginWithPassword Page.LoginWithPassword.init flags, Cmd.none )

        _ ->
            ( checkLoginStatusModel
            , Cmd.none
            )


init : Json.Decode.Value -> Url.Url -> Nav.Key -> ( Model, Cmd Msg )
init flagsValue url navKey =
    case Json.Decode.decodeValue flagsDecoder flagsValue of
        Ok flags ->
            let
                maybeRoute : Maybe Route.Route
                maybeRoute =
                    Route.fromUrl url

                model =
                    Model navKey url (Just flags) Unknown False ""
            in
            toState flags model maybeRoute

        _ ->
            ( { key = navKey
              , url = url
              , flags = Nothing
              , state = InvalidFlags
              , auth = Unknown
              , openMenu = False
              , searchKeyword = ""
              }
            , Cmd.none
            )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    case ( model.state, msg ) of
        ( _, UrlRequested req ) ->
            case req of
                Browser.Internal url ->
                    ( model, Nav.pushUrl model.key (Url.toString url) )

                Browser.External url ->
                    ( model, Nav.load url )

        ( _, UrlChanged url ) ->
            case model.flags of
                Nothing ->
                    ( model, Cmd.none )

                Just flags ->
                    let
                        maybeRoute : Maybe Route.Route
                        maybeRoute =
                            Route.fromUrl url

                        newModel =
                            Model model.key model.url (Just flags) model.auth False ""
                    in
                    toState flags newModel maybeRoute

        ( _, SearchTextChanged keyword ) ->
            ( { model | searchKeyword = keyword }, Cmd.none )

        ( _, SearchSelected ) ->
            ( model
            , Nav.load ("/articles/" ++ model.searchKeyword)
            )

        ( Home hs flags, HomeMsg hm ) ->
            let
                ( homeState, homeCmd ) =
                    Page.Home.update hm hs
            in
            ( { model | state = Home homeState flags }, Cmd.map HomeMsg homeCmd )

        ( Login ls flags, LoginMsg lm ) ->
            let
                ( loginState, loginCmd ) =
                    Page.Login.update lm ls
            in
            ( { model | state = Login loginState flags }, Cmd.map LoginMsg loginCmd )

        ( LoginWithPassword ls flags, LoginWithPasswordMsg lm ) ->
            let
                ( loginWithPasswordState, loginWithPasswordCmd ) =
                    Page.LoginWithPassword.update lm ls
            in
            ( { model | state = LoginWithPassword loginWithPasswordState flags }, Cmd.map LoginWithPasswordMsg loginWithPasswordCmd )

        ( Article _ _, ArticleMsg Page.Article.Back ) ->
            ( model, Nav.back model.key 1 )

        ( Article _ _, ArticleMsg (Page.Article.SendScreenView screenViewInfo) ) ->
            ( model, sendScreenView screenViewInfo )

        ( Article s flags, ArticleMsg am ) ->
            let
                ( articleState, articleCmd ) =
                    Page.Article.update am s
            in
            ( { model | state = Article articleState flags }, Cmd.map ArticleMsg articleCmd )

        ( CEQuizzes cs flags, CEQuizzesMsg cm ) ->
            let
                ( ceQuizzesState, ceQuizzessCmd ) =
                    Page.Quizzes.update cm cs
            in
            ( { model | state = CEQuizzes ceQuizzesState flags }, Cmd.map CEQuizzesMsg ceQuizzessCmd )

        ( CEArticle _ _ _, CEArticleMsg (Page.CEArticle.SendScreenView screenViewInfo) ) ->
            ( model, sendScreenView screenViewInfo )

        ( CEArticle cas flags data, CEArticleMsg cam ) ->
            let
                ( ceArticleState, ceArticleCmd ) =
                    Page.CEArticle.update cam cas
            in
            ( { model | state = CEArticle ceArticleState flags data }, Cmd.map CEArticleMsg ceArticleCmd )

        ( Questions qs flags data, QuestionsMsg qm ) ->
            let
                ( questionState, questionsCmd ) =
                    Page.Questions.update qm qs
            in
            ( { model | state = Questions questionState flags data }, Cmd.map QuestionsMsg questionsCmd )

        ( Articles _ _, ArticlesMsg (Page.Articles.SendScreenView screenViewInfo) ) ->
            ( model, sendScreenView screenViewInfo )

        ( Articles cs flags, ArticlesMsg cm ) ->
            let
                ( articlesState, articlesCmd ) =
                    Page.Articles.update cm cs
            in
            ( { model | state = Articles articlesState flags }, Cmd.map ArticlesMsg articlesCmd )

        ( ClinicEducation cs flags, ClinicEducationMsg cm ) ->
            let
                ( clinicEducationState, clinicEducationCmd ) =
                    Page.ClinicEducation.update cm cs
            in
            ( { model | state = ClinicEducation clinicEducationState flags }, Cmd.map ClinicEducationMsg clinicEducationCmd )

        ( ClinicEducationArticle _ _, ClinicEducationArticleMsg Page.ClinicEducationArticle.Back ) ->
            ( model, Nav.back model.key 1 )

        ( ClinicEducationArticle _ _, ClinicEducationArticleMsg (Page.ClinicEducationArticle.SendScreenView screenViewInfo) ) ->
            ( model, sendScreenView screenViewInfo )

        ( ClinicEducationArticle s flags, ClinicEducationArticleMsg am ) ->
            let
                ( articleState, articleCmd ) =
                    Page.ClinicEducationArticle.update am s
            in
            ( { model | state = ClinicEducationArticle articleState flags }, Cmd.map ClinicEducationArticleMsg articleCmd )

        ( ClinicResource cs flags, ClinicResourceMsg cm ) ->
            let
                ( clinicResourceState, clinicResourceCmd ) =
                    Page.ClinicResource.update cm cs
            in
            ( { model | state = ClinicResource clinicResourceState flags }, Cmd.map ClinicResourceMsg clinicResourceCmd )

        ( ClinicResourceArticle _ _, ClinicResourceArticleMsg Page.ClinicResourceArticle.Back ) ->
            ( model, Nav.back model.key 1 )

        ( ClinicResourceArticle _ _, ClinicResourceArticleMsg (Page.ClinicResourceArticle.SendScreenView screenViewInfo) ) ->
            ( model, sendScreenView screenViewInfo )

        ( ClinicResourceArticle s flags, ClinicResourceArticleMsg am ) ->
            let
                ( articleState, articleCmd ) =
                    Page.ClinicResourceArticle.update am s
            in
            ( { model | state = ClinicResourceArticle articleState flags }, Cmd.map ClinicResourceArticleMsg articleCmd )

        ( Webinar _ _, WebinarMsg (Page.Webinar.ContainerReady email) ) ->
            ( model, iframeContainerReady email )

        ( Webinar cs flags, WebinarMsg cm ) ->
            let
                ( webinarState, webinarCmd ) =
                    Page.Webinar.update cm cs
            in
            ( { model | state = Webinar webinarState flags }, Cmd.map WebinarMsg webinarCmd )

        ( Register rs flags, RegisterMsg rm ) ->
            let
                ( registerState, registerCmd ) =
                    Page.Register.update rm rs
            in
            ( { model | state = Register registerState flags }, Cmd.map RegisterMsg registerCmd )

        ( Account acs flags, AccountMsg acm ) ->
            let
                ( accountState, accountCmd ) =
                    Page.Account.update acm acs
            in
            ( { model | state = Account accountState flags }, Cmd.map AccountMsg accountCmd )

        ( _, MenuSelected ) ->
            ( { model | openMenu = not model.openMenu }, Cmd.none )

        ( _, AuthChanged Nothing ) ->
            case model.state of
                CheckLoginStatus flags maybeRoute ->
                    let
                        newModel =
                            Model model.key model.url model.flags NotLogin False ""
                    in
                    toState flags newModel maybeRoute

                _ ->
                    ( { model | auth = NotLogin }, Cmd.none )

        ( _, AuthChanged (Just email) ) ->
            case model.flags of
                Just f ->
                    ( { model | auth = CheckingVetInfo email }
                    , User.getUser CheckUserExists <|
                        Url.Builder.crossOrigin f.ceServiceUrl
                            [ "users" ]
                            [ Url.Builder.string "email" email ]
                    )

                Nothing ->
                    ( model, Cmd.none )

        ( _, CheckUserExists res ) ->
            case res of
                Ok users ->
                    case ( model.auth, model.flags, users ) of
                        ( CheckingVetInfo email, Just f, [] ) ->
                            ( model, User.createUser UserCreated f.ceServiceUrl email )

                        ( CheckingVetInfo email, Just f, _ ) ->
                            ( model
                            , User.fetchVetInfo VetInfoFetched f.ceServiceUrl email
                            )

                        _ ->
                            ( model, Cmd.none )

                Err _ ->
                    ( model, Cmd.none )

        ( _, UserCreated res ) ->
            case ( res, model.auth, model.flags ) of
                ( Ok _, CheckingVetInfo email, Just f ) ->
                    ( model
                    , User.fetchVetInfo VetInfoFetched f.ceServiceUrl email
                    )

                _ ->
                    ( model, Cmd.none )

        ( _, VetInfoFetched res ) ->
            case res of
                Ok users ->
                    case ( model.auth, model.flags, users ) of
                        ( CheckingVetInfo email, Just flag, [] ) ->
                            let
                                ( registerState, registerCmd ) =
                                    Page.Register.init
                                        flag.ceServiceUrl
                                        email
                            in
                            ( { model | state = Register registerState flag, auth = NoInformation email }, Cmd.map RegisterMsg registerCmd )

                        ( CheckingVetInfo email, Just flag, _ ) ->
                            case model.state of
                                CheckLoginStatus flags maybeRoute ->
                                    let
                                        newModel =
                                            Model model.key model.url model.flags (LoggedIn email) False ""
                                    in
                                    toState flags newModel maybeRoute

                                _ ->
                                    ( { model | auth = LoggedIn email, state = Home (Page.Home.Idle flag) flag }
                                    , Nav.load "https://www.vetbuddyexpert.com/article/management-of-acute-and-chronic-myxomatous-mitral-valve-disease-in-dogs"
                                    )

                        _ ->
                            ( model, Cmd.none )

                Err _ ->
                    ( model, Cmd.none )

        ( _, Logout ) ->
            ( model, logout () )

        _ ->
            ( model, Cmd.none )


view : Model -> Browser.Document Msg
view { state, auth, openMenu, searchKeyword } =
    case state of
        InvalidFlags ->
            { title = "Vet Buddy Expert"
            , body =
                [ div [] [ text "Invalid Flags" ]
                ]
            }

        Login s _ ->
            { title = "Vet Buddy Expert | Login From Email"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map LoginMsg <| Page.Login.view s
                ]
            }

        Home hs _ ->
            { title = "Vet Buddy Expert | Home"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map HomeMsg <| Page.Home.view hs
                ]
            }

        Register hs _ ->
            { title = "Vet Buddy Expert | Register"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map RegisterMsg <| Page.Register.view hs
                ]
            }

        Account acs _ ->
            { title = "Vet Buddy Expert | Account"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map AccountMsg <| Page.Account.view acs
                ]
            }

        Article s _ ->
            { title = "Vet Buddy Expert | Article"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ArticleMsg <| Page.Article.view s
                ]
            }

        CEQuizzes s _ ->
            { title = "Vet Buddy Expert | CE Quizzes"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map CEQuizzesMsg <| Page.Quizzes.view s
                ]
            }

        CEArticle s _ _ ->
            { title = "Vet Buddy Expert | CE Article"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map CEArticleMsg <| Page.CEArticle.view s
                ]
            }

        Questions s _ _ ->
            { title = "Vet Buddy Expert | CE Article"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map QuestionsMsg <| Page.Questions.view s
                ]
            }

        Articles s _ ->
            { title = "Vet Buddy Expert | Search Articles"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ArticlesMsg <| Page.Articles.view s
                ]
            }

        ClinicEducation s _ ->
            { title = "Vet Buddy Expert | Clinic Education"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ClinicEducationMsg <| Page.ClinicEducation.view s
                ]
            }

        ClinicEducationArticle s _ ->
            { title = "Vet Buddy Expert | Clinic Education Article"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ClinicEducationArticleMsg <| Page.ClinicEducationArticle.view s
                ]
            }

        ClinicResource s _ ->
            { title = "Vet Buddy Expert | Digital Asset Hub"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ClinicResourceMsg <| Page.ClinicResource.view s
                ]
            }

        ClinicResourceArticle s _ ->
            { title = "Vet Buddy Expert | Digital Asset Hub Article"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map ClinicResourceArticleMsg <| Page.ClinicResourceArticle.view s
                ]
            }

        Webinar s _ ->
            { title = "Vet Buddy Expert | Webinar"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map WebinarMsg <| Page.Webinar.view s
                ]
            }

        Contact ->
            { title = "Vet Buddy Expert | Contact"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Page.Contact.view
                ]
            }

        PrivacyPolicy ->
            { title = "Vet Buddy Expert | นโยบายส่วนบุคคล และคุ้กกี้ (Cookie Policy)"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Page.PrivacyPolicy.view
                ]
            }

        CheckLoginStatus _ _ ->
            { title = "Vet Buddy Expert | Check Login Status"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , div [ class "flex flex-col items-center pt-16 min-h-screen" ]
                    [ h1 [ class "block font-bold my-4 text-2xl" ] [ text "กำลังตรวจสอบสถานะ login ครับ..." ]
                    ]
                ]
            }

        LoginWithPassword s _ ->
            { title = "Vet Buddy Expert | Login"
            , body =
                [ viewNavbar auth openMenu searchKeyword
                , Html.map LoginWithPasswordMsg <| Page.LoginWithPassword.view s
                ]
            }


viewHeader : Auth -> String -> Html.Html Msg
viewHeader auth searchKeyword =
    div []
        [ div [ class "flex justify-end", class "w-4/4 sm:w-4/4 md:w-4/4 lg:w-3/4 xl:w-3/4" ]
            [ div
                [ class " z-50 absolute top-0 right-0"
                , class "w-3/4"
                , class "hidden sm:hidden md:hidden lg:flex xl:flex"
                ]
                [ Assets.NewSvg.topBg "80%" ]
            , div
                [ class " z-50 absolute top-0 right-0"
                , class "w-4/4 "
                , class "flex sm:flex md:flex lg:hidden xl:hidden"
                ]
                [ Assets.NewSvg.topBg "100%" ]
            ]
        , div [ class " justify-end hidden sm:hidden md:hidden lg:flex xl:flex" ]
            [ div [ class "flex items-start mb-2 justify-center sm:justify-between md:justify-between lg:justify-between xl:justify-between flex-wrap  z-1" ]
                [ div [ class "", style "z-index" "100" ] [ viewSearchBar searchKeyword ]
                , div [ class "lg:flex items-center flex-row-reverse" ]
                    [ case auth of
                        Unknown ->
                            span [ style "z-index" "100" ] [ text "Waiting for login status..." ]

                        NotLogin ->
                            div
                                [ class "flex items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto bg-sky-100 p-2 rounded-xl mb-4 px-4"
                                , style "z-index" "100"
                                ]
                                [ a
                                    [ class "cursor-pointer text-xl"
                                    , style "z-index" "100"
                                    , href "/login"
                                    ]
                                    [ text "LOGIN" ]
                                ]

                        LoggedIn _ ->
                            div
                                [ class "flex items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto bg-sky-100 p-2 rounded-xl mb-4 px-4"
                                , style "z-index" "100"
                                ]
                                [ a
                                    [ class " text-xl"
                                    , href "/account"
                                    ]
                                    [ text "MY EXPERT CREDIT" ]
                                , span [ class "text-xl mx-4" ] [ text "|" ]
                                , span [ class "cursor-pointer text-xl", onClick Logout ] [ text "LOGOUT" ]
                                ]

                        NoInformation _ ->
                            div
                                [ class "flex items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto bg-sky-100 p-2 rounded-xl mb-4 px-4"
                                , style "z-index" "100"
                                ]
                                [ span [ class "cursor-pointer text-xl", onClick Logout ] [ text "LOGOUT" ]
                                ]

                        CheckingVetInfo _ ->
                            span [ style "z-index" "100" ] [ text "Checking registration information..." ]
                    ]
                ]
            ]
        ]


viewNavbar : Auth -> Bool -> String -> Html.Html Msg
viewNavbar auth isDisplay searchKeyword =
    let
        isDisplayStyle =
            if isDisplay then
                "w-full flex flex-col text-center bg-white absolute top-0  min-h-screen pt-12"

            else
                "w-full flex justify-between"

        isNotLoginDisplayStyle =
            if isDisplay then
                "w-full flex flex-col text-center bg-white absolute top-0 min-h-screen pt-12"

            else
                "w-full flex justify-start"

        isDisplayBG =
            if isDisplay then
                "bg-blue-100 py-6"

            else
                ""

        toMenu =
            case auth of
                LoggedIn _ ->
                    [ div [ class isDisplayStyle, style "z-index" "200" ]
                        [ a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700   leading-none text-gray-900  text-xl"
                            , class isDisplayBG
                            , class "py-6 lg:py-2 "
                            , href "/"
                            , style "z-index" "100"
                            ]
                            [ text "HOME" ]

                        -- , a
                        --     [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700  px-4 py-2 leading-none text-gray-900  text-xl"
                        --     , href "/articles/all"
                        --     ]
                        --     [ text "CE HUB" ]
                        , a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700   leading-none text-gray-900  text-xl"
                            , href "/ce"
                            , class "py-6 lg:py-2 "
                            , class isDisplayBG
                            , style "z-index" "100"
                            ]
                            [ text "CE HUB" ]
                        , a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700    leading-none text-gray-900  text-xl"
                            , class isDisplayBG
                            , class "py-6 lg:py-2 "
                            , href "/clinic-education/all"
                            , style "z-index" "100"
                            ]
                            [ text "CLINIC EDUCATION" ]
                        , a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700    leading-none text-gray-900  text-xl"
                            , class isDisplayBG
                            , class "py-6 lg:py-2 "
                            , href "/digital-asset-hub/all"
                            , style "z-index" "100"
                            ]
                            [ text "DIGITAL ASSET HUB" ]
                        , a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700  leading-none text-gray-900  text-xl"
                            , class isDisplayBG
                            , class "py-6 lg:py-2 "
                            , href "/live"
                            , style "z-index" "100"
                            ]
                            [ text "LIVE" ]
                        , a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700  leading-none text-gray-900  text-xl"
                            , class isDisplayBG
                            , class "py-6 lg:py-2 "
                            , href "/contact"
                            , style "z-index" "100"
                            ]
                            [ text "CONTACT" ]
                        , a
                            [ class "text-blue-900 items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto bg-whitw py-6 rounded-xl text-lg"
                            , style "z-index" "100"
                            , HtmlAttributes.hidden (not isDisplay)
                            , href "/account"
                            ]
                            [ text "MY EXPERT CREDIT" ]
                        , div
                            [ class "border-t-2-transparent  inline-block cursor-pointer hover:text-blue-700   leading-none text-black font-thin text-lg "
                            , class isDisplayBG
                            , class "cursor-pointer"
                            , class "py-6 lg:py-2 "
                            , class "block lg:hidden"
                            , onClick Logout
                            , style "z-index" "100"
                            ]
                            [ text "LOGOUT" ]
                        ]
                    ]

                NoInformation _ ->
                    [ span [] [] ]

                _ ->
                    [ div [ class isNotLoginDisplayStyle, style "z-index" "200" ]
                        [ a
                            [ class "border-t-2-transparent   inline-block cursor-pointer hover:text-blue-700  py-2 leading-none text-blackfont-thin  text-lg"
                            , class isDisplayBG
                            , href "/"
                            , style "z-index" "100"
                            ]
                            [ text "HOME" ]
                        , a
                            [ class "border-t-2-transparent   cursor-pointer hover:text-blue-700  py-2 leading-none text-gray-900 font-thin text-lg"
                            , class isDisplayBG
                            , HtmlAttributes.hidden (not isDisplay)
                            , href "/login"
                            , style "z-index" "100"
                            ]
                            [ text "LOGIN" ]
                        ]
                    ]

        toDisplayBurgurMenu =
            if isDisplay then
                div [ class "w-full bg-white flex justify-between flex-col text-center  ", HtmlAttributes.style "z-index" "400" ] <| toMenu

            else
                div [] []
    in
    div
        [ class "flex justify-center flex-col lg:flex-row"
        , class "px-0 sm:px-0 md:px-0 lg:px-64"
        ]
        [ div
            [ class "w-full"
            ]
            [ div [ class "my-header top-0 w-full z-50" ]
                [ div [ class "" ]
                    [ div
                        [ class " flex items-end pt-8 justify-between"
                        , class "px-4 sm:px-4 md:px-4 lg:px-0"
                        ]
                        [ div [ class "flex items-center flex-shrink-0 mr-6 relative " ]
                            [ div [ class "hidden  lg:flex " ] [ Assets.NewSvg.newLogo 120 ]
                            , div [ class "flex lg:hidden  justify-center" ] [ Assets.NewSvg.newSmallLogo 90 ]
                            ]
                        , div [ style "z-index" "200", class "flex w-3/4 justify-between flex-col " ]
                            [ div [] [ viewHeader auth searchKeyword ]
                            , div [ class "hidden lg:flex xl:flex" ] <| toMenu
                            ]
                        , div [ class "block  lg:hidden" ]
                            [ div
                                [ class "flex items-center px-3 py-2 border rounded "
                                , style "z-index" "100"
                                , onClick MenuSelected
                                ]
                                [ Assets.Svg.menu
                                ]
                            ]
                        ]
                    ]
                , div [ class "block lg:hidden" ]
                    [ div [ class "flex justify-center  relative" ]
                        [ toDisplayBurgurMenu
                        ]
                    ]
                ]
            ]
        , div [ class "block lg:hidden pt-6" ] [ viewSearchBar searchKeyword ]
        ]


viewSearchBar : String -> Html.Html Msg
viewSearchBar keyWord =
    form
        [ HtmlAttributes.class "flex item-center  text-gray-600 focus-within:text-gray-400 mr-4 py-1"
        , class "flex items-center w-full sm:w-auto md:w-auto lg:w-auto xl:w-auto bg-white "
        , class "border-t-2 border-b-2 lg:rounded-xl border-sky-100 lg:border-4"
        , onSubmit SearchSelected
        ]
        [ span
            [ HtmlAttributes.class " w-full flex items-center pl-2"
            ]
            [ input
                [ HtmlAttributes.type_ "search"
                , HtmlAttributes.name "q"
                , HtmlAttributes.class "p-1 text-sm text-white rounded-md focus:outline-none bg-white focus:text-gray-900 px-4 w-full"
                , class "text-lef lg:text-right"
                , class "pl-5 lg:pl-10"
                , HtmlAttributes.placeholder "SEARCH"
                , HtmlAttributes.attribute "autocomplete" "off"
                , HtmlAttributes.value keyWord
                , onInput SearchTextChanged
                ]
                []
            , button
                [ HtmlAttributes.type_ "submit"
                , HtmlAttributes.class "p-1 pr-5 lg:pr-1 focus:outline-none focus:shadow-outline"
                ]
                [ svg
                    [ SvgAttr.fill "none"
                    , SvgAttr.stroke "currentColor"
                    , SvgAttr.strokeLinecap "round"
                    , SvgAttr.strokeLinejoin "round"
                    , SvgAttr.strokeWidth "2"
                    , SvgAttr.viewBox "0 0 24 24"
                    , SvgAttr.class "w-6 h-6"
                    ]
                    [ Svg.path
                        [ SvgAttr.d "M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        ]
                        []
                    ]
                ]
            ]
        ]


main : Program Json.Decode.Value Model Msg
main =
    Browser.application
        { init = init
        , view = view
        , update = update
        , onUrlChange = UrlChanged
        , onUrlRequest = UrlRequested
        , subscriptions = \_ -> subscriptions
        }


subscriptions : Sub Msg
subscriptions =
    Sub.batch
        [ authChanged AuthChanged
        , Sub.map ArticlesMsg Page.Articles.subscriptions
        , Sub.map LoginWithPasswordMsg Page.LoginWithPassword.subscriptions
        ]
