import env from "./env";
import { useFuseSearch } from "./lib/fuseSearch";
import { Elm } from "./Main.elm";
firebase.initializeApp(env.firebaseConfig);
const analytics = firebase.analytics();

const app = Elm.Main.init({
  node: document.getElementById("app"),
  flags: {
    contentUrl: env.contentUrl,
    contentApiKey: env.contentApiKey,
    ceServiceUrl: env.ceServiceUrl,
  },
});

app.ports.sendScreenView.subscribe(function ({ title, category }) {
  firebase.analytics().logEvent("screen_view", {
    firebase_screen: title,
    firebase_screen_class: category,
  });
});

app.ports.sendSignInLink.subscribe(function (email) {
  var actionCodeSettings = {
    url: env.loginRedirectUrl,
    handleCodeInApp: true,
  };

  window.firebase
    .auth()
    .sendSignInLinkToEmail(email, actionCodeSettings)
    .then(function () {
      window.localStorage.setItem("emailForSignIn", email);
    })
    .catch(function (error) {
      console.log(`Error ${error}`);
    });
});

window.firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    analytics.setUserId(user.uid);
    updateUserPoints(user.email);
    app.ports.authChanged.send(user.email);
  } else {
    app.ports.authChanged.send(null);
  }
});

if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
  var email = window.localStorage.getItem("emailForSignIn");
  if (!email) {
    email = window.prompt("Please provide your email for confirmation");
  }

  firebase
    .auth()
    .signInWithEmailLink(email, window.location.href)
    .then(function (result) {})
    .catch(function (error) {});
}

app.ports.logout.subscribe(function () {
  localStorage.removeItem("emailForSignIn");
  firebase
    .auth()
    .signOut()
    .then(function () {
      window.location.reload();
    });
});

app.ports.searchPost.subscribe(async function (searchText) {
  const result = await useFuseSearch(
    env.contentUrl,
    env.contentApiKey,
    decodeURI(searchText)
  );

  app.ports.searchResultReceive.send(result);
});

const updateUserPoints = (email) => {
  const userEmail = email.toLowerCase().replace(/\s/g, "");
  const json = JSON.stringify({ email: userEmail });
  const xhr = new XMLHttpRequest();
  xhr.open("POST", `${env.ceServiceUrl}/user-points/`);
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.send(json);
};

app.ports.loginWithPassword.subscribe(function (user) {
  const { email, password } = user;
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .catch((err) => {
      app.ports.loginStatus.send("INVALID_EMAIL_PASSWORD");
    });
});

app.ports.signUpWithPassword.subscribe(function (user) {
  const { email, password } = user;
  firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .catch(function (error) {
      app.ports.loginStatus.send("EMAIL_ALREADY_EXIST");
    });
});

app.ports.forgotPassword.subscribe(function (email) {
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(function () {
      app.ports.loginStatus.send("SEND_FORGOT_SUCCESS");
    })
    .catch(function (error) {
      console.log(error);
      app.ports.loginStatus.send("INVALID_EMAIL");
    });
});

app.ports.iframeContainerReady.subscribe(function (email) {
  let timerId;
  let iframe = document.getElementById("vimeo_video");
  if (iframe) {
    var player = new Vimeo.Player(iframe);
    player.on("ended", function () {
      sendReport();
      setTimeout(() => {
        clearInterval(timerId);
      }, 1000);
    });

    player.on("playing", function () {
      sendReport();
      timerId = setInterval(() => sendReport(), 300000);
    });

    player.on("pause", function (data) {
      sendReport();
      setTimeout(() => {
        clearInterval(timerId);
      }, 1000);
    });

    const sendReport = function () {
      const userEmail = email.toLowerCase().replace(/\s/g, "");
      const json = JSON.stringify({ email: userEmail });
      const xhr = new XMLHttpRequest();
      xhr.open("POST", `${env.ceServiceUrl}/event-reports/`);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(json);
    };
  }
});
