const firebaseTest = {
  apiKey: "AIzaSyAGNk9QvM_hQH0jOnA6uk5ZPBzjX-tgL3M",
  authDomain: "vet-buddy-expert-test.firebaseapp.com",
  databaseURL: "https://vet-buddy-expert-test.firebaseio.com",
  projectId: "vet-buddy-expert-test",
  storageBucket: "vet-buddy-expert-test.appspot.com",
  messagingSenderId: "868873806148",
  appId: "1:868873806148:web:4b213674c5f421b1f83711",
  measurementId: "G-SZZH6VDKX3",
};
function getEnv() {
  // if (process.env.BUILD_ENV === "production") {
  return {
    contentUrl: "https://ghost.vetbuddyexpert.com",
    contentApiKey: "4ecfd91d709c1c815107e8d1d3",
    ceServiceUrl: "https://ce-credit.vetbuddyexpert.com",
    loginRedirectUrl: "https://www.vetbuddyexpert.com/",
    firebaseConfig: {
      apiKey: "AIzaSyBH29HGuQNdtxwTnGOa_QFkBYg6b3CvFiY",
      authDomain: "vet-buddy-expert.firebaseapp.com",
      databaseURL: "https://vet-buddy-expert.firebaseio.com",
      projectId: "vet-buddy-expert",
      storageBucket: "vet-buddy-expert.appspot.com",
      messagingSenderId: "182718634835",
      appId: "1:182718634835:web:92866ff57758a4b3885fa6",
      measurementId: "G-C8JMEKSL8Y",
    },
  };
  // } else if (process.env.BUILD_ENV === "testserver") {
  //   return {
  //     contentUrl: "https://vbe-ghost.mumupetguide.com",
  //     contentApiKey: process.env.GHOST_CONTENT_API_KEY,
  //     ceServiceUrl: "https://vbe-ce-credit.mumupetguide.com",
  //     loginRedirectUrl: "https://vbe.mumupetguide.com/",
  //     firebaseConfig: firebaseTest,
  //   };
  // } else {
  //   return {
  //     contentUrl: "https://ghost.vetbuddyexpert.com",
  //     contentApiKey: "4ecfd91d709c1c815107e8d1d3",
  //     ceServiceUrl: "http://localhost:3000",
  //     loginRedirectUrl: "http://localhost:8888",
  //     firebaseConfig: firebaseTest,
  //   };
  // }
}

export default getEnv();
