import Fuse from "fuse.js";

export const useFuseSearch = async (rootUrl, key, searchText) => {
  if (!rootUrl) {
    console.warn("No rootUrl url specified. Using relative path");
  }
  if (!key) {
    throw new Error("No content api key found: Q_GHOST_API_KEY is undefined");
  }
  const fuseOptions = {
    keys: [{ name: "title" }],
  };
  const apiPath = "/ghost/api/v3/content/posts/";
  let fuseSearch = "";
  const url = rootUrl + apiPath + "?limit=" + "all" + "&key=" + key;

  const response = await fetch(url);
  const { meta, posts } = await response.json();
  fuseSearch = new Fuse(posts, fuseOptions);
  const lastResult = fuseSearch.search(searchText);
  return lastResult;
};
